import getLocale from "../utils/getLocale";
import React, { useEffect, useState } from "react";

interface IContext {
  locale: string;
  setLocale: (locale: string) => void;
}

const GlobalContext = React.createContext({} as IContext);

const GlobalProvider: React.FC<any> = (props: any) => {
  const [locale, setLocale] = useState("mn");

  useEffect(() => {
    setLocale(getLocale({ pathname: window?.location.pathname }));
  }, []);

  return (
    <GlobalContext.Provider value={{ locale, setLocale }}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export { GlobalProvider };
export default GlobalContext;
