import languages from "../data/languages";

// pass location from page as props
const getLocale = (location: any) => {
  const keys = languages.langs;
  const { pathname } = location;

  const lang = keys.find(
    (k) => pathname.includes(`/${k}/`) || pathname.includes(`/${k}`)
  );

  if (!lang) {
    return languages.defaultLangKey;
  }

  return lang;
};

export default getLocale;
